import React, { ChangeEvent, useEffect } from "react";
import styled from "@emotion/styled";
import usePagination from "@mui/material/usePagination";
import button from "styles/button.module.css";

interface PaginationProps {
  defaultPage: number;
  count: number;
  onChange: (event: ChangeEvent<unknown>, page: number) => void;
}

const Pagination = ({ defaultPage, count, onChange }: PaginationProps) => {
  const List = styled("ul")({
    listStyle: "none",
    padding: 0,
    margin: 10,
    display: "flex",
    alignItems: "center",
    gap: 7,
  });
  
  const { items } = usePagination({
    count,
    defaultPage,
    onChange,
  });
  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                className={`${button.pageButton} ${
                  selected && button.pageButtonSelected
                }`}
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                className={button.buttonPrimaryText}
                style={{ fontWeight: "bold", textTransform: "capitalize" }}
                {...item}
              >
                {type}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
};

export default Pagination;
