import React from "react";
import styles from "./navbar.module.css";
import button from "styles/button.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/authReducer";
import { logoutApi } from "datasource/Api";
import { useMutation } from "@tanstack/react-query";
import PageLoader from "components/loaders/Loader";

const Navbar = () => {
  const dispatch = useDispatch();
  const merchantName = useSelector(
    ({ auth }: { auth: any }) => auth.user?.name
  );
  const { isPending, mutate } = useMutation({
    mutationFn: logoutApi,

    onSuccess() {
      dispatch(logout());
    },
  });
  return (
    <>
      <div className={styles.navbar}>
      {isPending && <PageLoader  />}
        <div className={styles.navPart}>
          <div className={styles.welcomeText}>Welcome to</div>
          <img src="/logo.svg" alt="Deema logo" width={100} height={30} />
        </div>
        <div className={styles.navPart}>
          <div className={styles.merchantName}>{merchantName}</div>
          <button
            onClick={()=>mutate()}
            className={`${button.buttonSecondary} ${button.buttonSm}`}
          >
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
