import React from "react";
import styles from "./PendingMerchant.module.css";
import icons from "styles/icons.module.css";

const PendingMerchant = () => {
  return (
    <div className={styles.wrapper}>
      <i className={`${icons.sandWatch} ${styles.mainIcon}`} />
      <div className={styles.title}>Your account is being reviewed</div>
      <div className={styles.description}>
        Our team is reviewing your business account and soon you will be able to
        access your account to upload your business documents in order to start
        your partnership with Deema
      </div>
      <div className={styles.bottomLine}>
        Please wait for a confirmation email
      </div>
    </div>
  );
};

export default PendingMerchant;
