import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import App from "App";
import { Provider } from "react-redux";
import { store } from "store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      light: "#FAA616",
      main: "#FAA616",
      dark: "#FAA616",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
const Root = () => {
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <Provider store={store} >
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <App />
        </Provider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

root.render(<Root />);
