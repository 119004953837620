import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  getMerchentBranches,
  updateBranch,
} from "datasource/Api";
import { CreateBranchType } from "datasource/Types";
import BranchForm from "./BranchForm";
import Loader from "components/loaders/Loader";
import { PERMISSIONS } from "config/permissions";
import WithCheckPermission from "components/WithCheckPermission";
function UpdateBranch() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["branches"],
    queryFn: () => getMerchentBranches(),
  });
  const branch = data?.data.find((branch) => `${branch.id}` == id);
  const { isPending, mutate } = useMutation({
    mutationFn: updateBranch,

    onSuccess(data) {
      toast.success(" branch was updated successfully", {
        autoClose: 2000,
        position: "bottom-right",
      });
      refetch();
      navigate("/branches");
    },
    onError(error){
      
      toast.error("something wrong , maybe the name is taken ", {
        autoClose: 2000,
        position: "bottom-right",
      });
    }
  });
  const initialValues = {
    name: branch?.name || "",
  };
  const handleSubmit = (values: CreateBranchType) => {
    mutate({ values, id: `${id}` });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <BranchForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isLoding={isPending}
      title="Update branch"
    />
  );
}

export default WithCheckPermission(UpdateBranch, PERMISSIONS.branch_management);
