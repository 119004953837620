import { CircularProgress } from '@mui/material';
import React from 'react'
import shared from "styles/shared.module.css";

function Loader() {
  return (
    <div className={shared.loaderWrapper}>
    <CircularProgress color="inherit" />
  </div>
  )
}

export default Loader