import { getDocuments, submitDocumentsRequest } from "datasource/Api";
import React, { useEffect, useState } from "react";
import shared from "styles/shared.module.css";
import styles from "./InitiallyApprovedMerchant.module.css";
import icons from "styles/icons.module.css";
import button from "styles/button.module.css";
import { FileUploader } from "shared/fileUploader";
import { CircularProgress, Grid } from "@mui/material";
import { FileStatus } from "datasource/Types";
import { TEXT_NUMBERS, downloadBinary } from "utils";
import { toast } from "react-toastify";

const initFiles = {
  commercial_license: null,
  chambre_of_commerce_register: null,
  company_vat_certificate: null,
  signatory_certificate: null,
  authorized_signatories_ids: null,
};

const filesLabels: Record<string, string> = {
  commercial_license: "Attach Company licesnse*",
  chambre_of_commerce_register: "Attach Company registry*",
  company_vat_certificate: "Attach Company VAT certificate*",
  signatory_certificate: "Attach signature certificate*",
  authorized_signatories_ids: "Attach Authorized signatory papers*",
};

const InitiallyApprovedMerchant = () => {
  const [filesToUpload, setFilesToupload] = useState({ ...initFiles });
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alreadyUploaded, setAlreadyUploaded] = useState({ ...initFiles });
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [managedFilesArray, setManagedFilesArray] = useState<any[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any>({ ...initFiles });
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setReadyToSubmit(Object.values(filesToUpload).some((x) => x !== null));
  }, [filesToUpload]);

  useEffect(() => {
    setFetchingFiles(true);
    getDocuments()
      .then((res) => {
        res.data.forEach((e: any) => {
          setAlreadyUploaded((files) => ({
            ...files,
            [e.name]: {
              ...e,
              lastModifiedDate: e.created_at,
            },
          }));
        });
      })
      .finally(() => {
        setFetchingFiles(false);
      });
  }, []);

  useEffect(() => {
    const allFiles = Object.entries(alreadyUploaded).map(
      ([fileName, uploadedFile]: any) => ({
        uploadedFile,
        label: filesLabels[fileName],
        fileName,
        status: uploadedFile?.status,
      })
    );
    allFiles.forEach(({ fileName, status }) => {
      setStatuses((statuses: any) => ({
        ...statuses,
        [fileName]: status,
      }));
    });

    const rejectedFiles = allFiles.filter(
      (file) => file.uploadedFile?.status === FileStatus.REJECTED
    );
    const resetOfFiles = allFiles.filter(
      (file) => file.uploadedFile?.status !== FileStatus.REJECTED
    );
    setManagedFilesArray(resetOfFiles);
    setRejectedFiles(rejectedFiles);
  }, [alreadyUploaded]);

  const submitDocuments = () => {
    const requestPayload = new FormData();
    requestPayload.append("provider", "merchant");
    Object.entries(filesToUpload).forEach(([key, value]) => {
      if (value) {
        requestPayload.append(`files[${key}]`, value);
      }
    });
    setLoading(true);
    setSubmitting(true);
    submitDocumentsRequest(requestPayload)
      .then(() => {
        setFilesToupload({ ...initFiles });
        toast.success("Documents uploaded successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((res) => {
        toast.error("Documents upload faild", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const cancelFile = (file: any) => {
    setStatuses((statuses: any) => ({
      ...statuses,
      [file.fileName]: FileStatus.PENDING,
    }));
    setFilesToupload((files) => ({
      ...files,
      [file.fileName]: null,
    }));
  };

  if (fetchingFiles) {
    return (
      <div className={shared.page}>
        <div className={styles.firstLine}>You are almost there!</div>
        <div className={styles.title}>
          Your account was initiated. Please upload the required documents
        </div>
        <div className={shared.loaderWrapper}>
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  return (
    <div className={shared.page}>
      <div className={styles.firstLine}>You are almost there!</div>
      <div className={styles.title}>
        Your account was initiated. Please upload the required documents
      </div>
      <div className={styles.ctaBar}>
        <div className={styles.instructions}>
          <i className={icons.fileUpload} />
          <div>
            <div>Accepted file formats are PDF, JPEG, PNG</div>
            <div>Max size 100 MB</div>
          </div>
        </div>
        <button
          disabled={!readyToSubmit || loading}
          className={`${button.buttonPrimary} ${button.lg} ${
            button.slightlyRounded
          } ${(!readyToSubmit || loading) && button.disabled}`}
          onClick={submitDocuments}
        >
          submit documents
          {submitting && <CircularProgress size={16} color="inherit" />}
        </button>
      </div>

      <Grid style={{ marginBottom: "50px" }} container spacing={2}>
        {managedFilesArray.map((file) => (
          <Grid key={`${file.fileName}-${file.uploadedFile?.name}`} item xs={6}>
            <FileUploader
              alreadyUploaded={file.uploadedFile}
              label={file.label}
              accepted={statuses[file.fileName] === FileStatus.APPROVED}
              readonly={statuses[file.fileName] === FileStatus.APPROVED}
              download={() => downloadBinary(file.uploadedFile.path, file.uploadedFile.name + ".pdf")}
              onSubmit={(submittedFile) =>
                setFilesToupload((files) => ({
                  ...files,
                  [file.fileName]: submittedFile,
                }))
              }
              onCancel={() => cancelFile(file)}
            />
          </Grid>
        ))}
      </Grid>

      {rejectedFiles.length !== 0 && (
        <>
          <div className={styles.title}>
            {TEXT_NUMBERS[rejectedFiles.length]} document(s) has been rejected
          </div>

          <Grid container spacing={2}>
            {rejectedFiles.map((file) => (
              <Grid
                key={`${file.fileName}-${file.uploadedFile?.name}`}
                item
                xs={6}
              >
                <FileUploader
                  alreadyUploaded={file.uploadedFile}
                  download={() => downloadBinary(file.uploadedFile.path, file.uploadedFile.name + ".pdf")}
                  label={file.label}
                  errorText={
                    statuses[file.fileName] === FileStatus.REJECTED
                      ? file.uploadedFile.rejection_reason
                      : ""
                  }
                  rejected={statuses[file.fileName] === FileStatus.REJECTED}
                  onSubmit={(submittedFile) =>
                    setFilesToupload((files) => ({
                      ...files,
                      [file.fileName]: submittedFile,
                    }))
                  }
                  onCancel={() => cancelFile(file)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
};

export default InitiallyApprovedMerchant;
