import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Divider,
  Skeleton,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";
import { toast } from "react-toastify";

import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PhoneNumberInput from "components/inputs/phone-number-input";
import { useQuery, useMutation } from "@tanstack/react-query";
import QRCode from "react-qr-code";

import {
  getAvailableBranches,
  getMerchentDetails,
  placeOrderInStore,
} from "datasource/Api";
import Loader from "components/loaders/Loader";
function CraeteOrderForm({ type }: { type: "QR_CODE" | "SMS" }) {
  const ValidationSchema = Yup.object().shape({
    amount: Yup.number().required("required"),
    branch_id: Yup.number().required("required"),
    merchant_reference: Yup.string().required("required"),
    ...(type == "SMS" && {
      customer_phone_number: Yup.string().required("required"),
    }),
  });
  const [redirectLink, setRedirectLink] = useState("");
  const { isPending, mutate, reset } = useMutation({
    mutationFn: placeOrderInStore,

    onSuccess(data) {
      if (type == "SMS") {
        toast.success("redirect link sent by sms successfully", {
          autoClose: 2000,
          position: "top-center",
        });
        return;
      }
      setRedirectLink(data?.data?.data?.redirect_link);
    },
  });
  const initialValues = {
    amount: '',
    branch_id: '',
    notify_by_sms: type == "SMS" ? 1 : 0,
    customer_phone_number: "",
    merchant_reference: '',
  };
  const handleSubmit = (values: any, formikOptions: any) => {
    mutate(values);
  };
  const { data } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getMerchentDetails(),
  });
  const { data: branchesData } = useQuery({
    queryKey: ["available-branches", "offline"],
    queryFn: () => getAvailableBranches(false),
  });
  const currency = data?.data?.data?.currency;
 
  return (
    <>
      {type == "SMS" && isPending && <Loader />}
      <Box>
        <Typography variant="h5" mb={5} fontWeight="700">
          {type === "QR_CODE" ? "Create QR Code" : "Send payment link by SMS"}
        </Typography>
        <Formik
          validateOnChange
          validateOnBlur
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <Grid spacing={3} container>
                <Grid item xs={12} lg={5}>
                  {" "}
                  <Grid spacing={3} container mb={4}>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="filled-adornment-amount">
                          Amount
                        </InputLabel>
                        <OutlinedInput
                          value={props.values.amount}
                          label="Amount"
                          name="amount"
                          onChange={props.handleChange}
                          error={!!props.errors.amount}
                          id="filled-adornment-amount"
                          startAdornment={
                            <InputAdornment position="start">
                              {currency}
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {props.errors.amount}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={props.values.merchant_reference}
                        onChange={props.handleChange}
                        variant="outlined"
                        error={!!props.errors.merchant_reference}
                        fullWidth
                        label="merchant reference"
                        name="merchant_reference"
                      />
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {props.errors.merchant_reference}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="branches">branche</InputLabel>
                        <Select
                          {...props.getFieldProps({ name: "branch_id" })}
                          variant="outlined"
                          error={!!props.errors.branch_id}
                          name="branch_id"
                          labelId="branch_id"
                          label="branch"
                          fullWidth
                        >
                          {branchesData?.data.map((branch) => (
                            <MenuItem value={branch.id}>{branch.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {props.errors.branch_id}
                      </FormHelperText>
                    </Grid>
                    {type == "SMS" && (
                      <Grid item xs={12}>
                        <PhoneNumberInput
                        
                          value={props.values.customer_phone_number}
                          error={props.errors.customer_phone_number}
                          onChange={(value: any) =>
                            props.setFieldValue(
                              "customer_phone_number",
                              `${value}`
                            )
                          }
                          label="merchant reference"
                          name="customer_phone_number"
                        />
                        <FormHelperText
                          sx={(theme) => ({
                            color: theme.palette.error.main,
                          })}
                        >
                          {props.errors.customer_phone_number}
                        </FormHelperText>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          type="submit"
                          sx={{ boxShadow: "none" }}
                          size="large"
                          variant="contained"
                        >
                          {type == "QR_CODE"
                            ? " Generate Qr code"
                            : "Notify by sms"}
                        </Button>
                        <Button
                          type="button"
                          sx={{ boxShadow: "none" }}
                          size="large"
                          onClick={()=>{
                            props.resetForm()
                            reset()
                            setRedirectLink('')
                          }}
                          variant="outlined"
                        >
                         reset form
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                {type === "QR_CODE" && (
                  <>
                    {" "}
                    <Grid justifyContent="end" display="flex" lg={2} xs={12}>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <Stack alignItems="center">
                        {isPending ? (
                          <Skeleton
                            variant="rectangular"
                            sx={{ borderRadius: 3 }}
                            width={300}
                            height={300}
                          />
                        ) : redirectLink ? (
                          <QRCode
                            size={300}
                            style={{
                              height: "auto",
                              maxWidth: 300,
                              borderRadius: 3,
                            }}
                            value={redirectLink}
                            viewBox={`0 0 256 256`}
                          />
                        ) : (
                          <Box
                            sx={{
                              width: 300,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 3,
                              height: 300,
                              border: 1,
                              borderStyle: "solid",
                              borderRadius: 3,
                            }}
                          >
                            <Typography variant="body1" textAlign="center">
                              complate create order form to Generate QR code{" "}
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default CraeteOrderForm;
