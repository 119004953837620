import { getUserDetails } from "datasource/Api";
import { MerchantStatus } from "datasource/Types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { savePermissinos } from "store/authReducer";

function useGetPermissinos() {
  const dispatch = useDispatch();

  const approvedMerchant =
    (useSelector(({ auth }: { auth: any }) => auth.user?.status) || null) ===
    MerchantStatus.APPROVED;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (approvedMerchant) {
      setLoading(true);
      getUserDetails()
        .then((res) => {
          setLoading(false);
          dispatch(
            savePermissinos(
              res?.data?.roles[0]?.permissions?.map((per) => per.key)
            )
          );
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [approvedMerchant]);
  return { loading };
}

export default useGetPermissinos;
