import React, { useState } from "react";
import { DropZone } from "./DropZone";
import { SubmittedFile } from "./SubmittedFile";
import styles from "./FileUploader.module.css";

type TProps = {
  onSubmit?: (file: any) => void;
  onCancel?: () => void;
  label: string;
  alreadyUploaded: any;
  readonly?: boolean;
  accepted?: boolean;
  rejected?: boolean;
  errorText?: string;
  error?: string;
  download?: () => void
};

const FileUploader = ({
  onSubmit,
  onCancel,
  label,
  alreadyUploaded,
  readonly = false,
  accepted,
  rejected,
  errorText,
  download
}: TProps) => {
  const [submittedFile, setSubmittedFile] = useState(alreadyUploaded);

  const droped = (file: any) => {
    setSubmittedFile(file);
    if (!readonly && onSubmit) {
      onSubmit(file);
    }
  };

  const cancel = () => {
    setSubmittedFile(null);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div>
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{label}</label>
        <div
          className={`${styles.status} ${accepted && styles.success} ${
            rejected && styles.error
          }`}
        >
          {accepted ? "Accepted" : rejected ? "Rejected" : ""}
        </div>
      </div>
      {!submittedFile ? (
        <DropZone submitFile={droped} />
      ) : (
        <SubmittedFile
          readonly={readonly}
          accepted={accepted}
          rejected={rejected}
          file={submittedFile}
          cancel={cancel}
          download={download}
        />
      )}
      {errorText && (
        <div style={{ color: "#E55E5E", marginTop: 4 }}>{errorText}</div>
      )}
    </div>
  );
};

export default FileUploader;
