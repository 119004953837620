import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  PickersShortcutsItem,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

function DateRangeInput({
  value,
  onChange,
}: {
  value: DateRange<Dayjs>;
  onChange: (date: DateRange<Dayjs>) => void;
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        sx={{ flex: 1 }}
        value={value}
        onChange={onChange}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },

          textField: { size: "small" },
          actionBar: { actions: [] },
        }}
        slots={{ field: SingleInputDateRangeField }}
        name="allowedRange"
      />
    </LocalizationProvider>
  );
}

export default DateRangeInput;

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },

  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const prevMonth = today.subtract(30, "day");
      return [prevMonth.startOf("month"), prevMonth.endOf("month")];
    },
  },
  {
    label: "Current Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("year")];
    },
  },

  { label: "Reset", getValue: () => [null, null] },
];
