import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAuthintecated = () => {
  const isLogedin = useSelector(({ auth }: { auth: any }) => auth.isLogedin);
  const navigate = useNavigate();

  useEffect(() => {
    console.clear()

    if (!isLogedin) {
      navigate("/login", {
        replace: true,
      });
    }
  }, [isLogedin]);
};

export default useAuthintecated;
