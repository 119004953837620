import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createUser } from "datasource/Api";
import {CreateUserType } from "datasource/Types";
import BranchForm from "./UserForm";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
function CreateUser() {
  const navigate = useNavigate();
  const { isPending, mutate } = useMutation({
    mutationFn: createUser,

    onSuccess(data) {
      toast.success("new user was created successfully", {
        autoClose: 2000,
        position: "bottom-right",
      });
      navigate("/users");
    },
    onError(error) {
      toast.error("something wrong , maybe the email is taken ", {
        autoClose: 2000,
        position: "bottom-right",
      });
    },
  });
  const initialValues = {
    full_name: "",
    email: "",
    role_id:"",
    branches: [],
  };
  const handleSubmit = (values: CreateUserType) => {
    mutate(values);
  };

  return (
    <BranchForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isLoding={isPending}
      title="Create new User"
    />
  );
}

export default WithCheckPermission(CreateUser, PERMISSIONS.user_management);
