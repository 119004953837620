import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getMerchentUserById, updateUser } from "datasource/Api";
import { CreateUserType } from "datasource/Types";
import BranchForm from "./UserForm";
import Loader from "components/loaders/Loader";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
function UpdateUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["users", id],
    queryFn: () => getMerchentUserById(`${id}`),
  });
  const user = data?.data;
  const { isPending, mutate } = useMutation({
    mutationFn: updateUser,

    onSuccess(data) {
      toast.success(" user was updated successfully", {
        autoClose: 2000,
        position: "bottom-right",
      });
      refetch();
      navigate("/users");
    },
    onError(error) {
      toast.error("something wrong , maybe the email is taken ", {
        autoClose: 2000,
        position: "bottom-right",
      });
    },
  });
  const initialValues: CreateUserType = {
    full_name: user?.full_name || "",
    email: user?.email || "",
    role_id: user?.roles[0]?.id || "",
    branches: user?.branches.map(branch=>branch.id) || [],
  };
  const handleSubmit = (values: CreateUserType) => {
    mutate({ values, id: `${id}` });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <BranchForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isEdit
      isLoding={isPending}
      title="Update user"
    />
  );
}

export default WithCheckPermission(UpdateUser, PERMISSIONS.user_management);
