import WithCheckPermission from "components/WithCheckPermission";
import CreateOrderForm from "./CreateOrderForm";
import { PERMISSIONS } from "config/permissions";
import { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import shared from "styles/shared.module.css";
import { QrCodeScannerOutlined, LinkOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";

enum PlaceOrderInStoreTypes {
  SMS = "SMS",
  QR_CODE = "QR_CODE",
}

const StyledTabList = styled(TabList)(({ theme }) => ({
  borderBottom: "none",
  width:"100%",
  position: "relative",
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    borderRadius: "8px",
    zIndex: -1, 
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  borderRadius: "8px",
  margin: "0 8px",
  transition: "color 0.3s",
  border :'solid 1px',
  borderColor:theme.palette.primary.main,
  zIndex: 1, 
  "&.Mui-selected": {
    color: theme.palette.common.white,
  },
  "&.MuiTab-root": {
    minHeight: "48px", 
  },
}));


function GenerateQRCodePage() {
  const [value, setValue] = useState(PlaceOrderInStoreTypes.SMS);
  const handleChange = (event: React.SyntheticEvent, newValue: PlaceOrderInStoreTypes) => {
    setValue(newValue);
  };

  return (
    <div className={shared.page}>
      <TabContext value={value}>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <StyledTabList
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <StyledTab
              label="Generate Payment Link"
              icon={<LinkOutlined />}
              iconPosition="start"
              value={PlaceOrderInStoreTypes.SMS}
            />
            <StyledTab
              label="Generate QR Code"
              iconPosition="start"
              icon={<QrCodeScannerOutlined />}
              value={PlaceOrderInStoreTypes.QR_CODE}
            />
          </StyledTabList>
        </Box>
        <TabPanel value={PlaceOrderInStoreTypes.SMS}>
          <CreateOrderForm type={PlaceOrderInStoreTypes.SMS} />
        </TabPanel>
        <TabPanel value={PlaceOrderInStoreTypes.QR_CODE}>
          <CreateOrderForm type={PlaceOrderInStoreTypes.QR_CODE} />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default WithCheckPermission(
  GenerateQRCodePage,
  PERMISSIONS.place_in_store_purchase
);
