import { Box } from "@mui/material";
import usePermissionsChecker from "utils/hooks/usePermissionsChecker";

const WithCheckPermission = (BaseComponent: any, permission: string) => {
  return function EnhancedComponent(props: any) {
    const { hasPermissionTo } = usePermissionsChecker();
    return hasPermissionTo(permission) ? (
      <BaseComponent {...props} enhancedProp="someValue" />
    ) : (
      <Box   sx={(theme) => ({
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        width:"max-content",
        height: "100%",
        fontSize: "110px",
        fontWeight: "700",
        pt: "103px",
        color: theme.palette.primary.main,
        alignItem: "center",
        justifyContent: "center",
        textAlign:"center"
      })}>
       
      </Box>
    );
  };
};
export default WithCheckPermission;
