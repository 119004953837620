import React, { useRef, useState } from "react";
import styles from "./login.module.css";
import icons from "styles/icons.module.css";
import { TextField } from "@mui/material";
import button from "styles/button.module.css";
import { login } from "datasource/Api";
import { useDispatch } from "react-redux";
import { persistAuthData } from "store/authReducer";
import { useNotAuthintecated } from "utils/hooks";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_KEY } from "config/base";

const EMAIL_REGEX = /\S+@\S+\.\S+/;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  useNotAuthintecated();
  const [isVerified, setIsVerified] = useState(false);

  const dispatch = useDispatch();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const resetRecaptcha = () => {
    if (isVerified) {
      recaptchaRef.current?.reset();
      setIsVerified(false);
    }
  };
  const handleEmailChanged = (event: any) => {
    const email = event.target.value;
    setEmail(email);
    validateEmail(email);
    resetRecaptcha();
  };

  const handlePasswordChanged = (event: any) => {
    const password = event.target.value;

    resetRecaptcha();

    validatePassword(password);
    setPassword(event.target.value);
  };

  const handleVerification = () => {
    setIsVerified(true);
  };

  const loginRequest = () => {
    if (!isVerified || loading || !validateForm()) {
      return;
    }

    setLoading(true);
    setError("");
    login({ email, password })
      .then((res) => {
        dispatch(persistAuthData(res.data));
      })
      .catch((error) => {
        setError(error?.response?.data?.message);

      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateEmail = (email: string) => {
    const valid = EMAIL_REGEX.test(email);
    setEmailError(!valid);
    return valid;
  };

  const validatePassword = (password: string) => {
    const valid = !!password;
    setPasswordError(!valid);
    return valid;
  };

  const validateForm = () => {
    return validateEmail(email) && validatePassword(password);
  };

  const height = 44;
  // height of the TextField

  // magic number which must be set appropriately for height
  const labelOffset = -6;

  // get this from your form library, for instance in
  // react-final-form it's fieldProps.meta.active
  // or provide it yourself - see notes below
  const focused = "Mui-focusd";

  return (
    <div className={styles.wrapper}>
      <div className={styles.loginBg} />
      <div className={styles.loginSection}>
        <div className={styles.loginContent}>
          <i className={`${icons.coloredLogo} ${styles.logo}`} />
          <p className={styles.loginText}>
            Welcome back to Deema Your BNPL Partner!
          </p>
          {error && (
            <div className={styles.error}>{error}</div>
          )}
          <TextField
            fullWidth
            onChange={handleEmailChanged}
            error={emailError}
            helperText={emailError ? "Please enter a valid email" : ""}
            label="Business Email"
            size="small"
          />
          <TextField
            fullWidth
            onChange={handlePasswordChanged}
            error={passwordError}
            helperText={
              passwordError ? "Please enter the account password" : ""
            }
            size="small"
            label="Password"
            type="password"
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={GOOGLE_RECAPTCHA_KEY}
            onExpired={() => setIsVerified(false)}
            
            onChange={handleVerification}
            size="normal"
          />
          <button
            style={{ marginTop: 10, opacity: isVerified ? 1 : 0.5 }}
            onClick={loginRequest}
            type="submit"
            disabled={!isVerified}
            className={`${button.buttonPrimary} ${button.block} ${
              loading ? button.disabled : ""
            }`}
          >
            <span className={button.centerText}>Login</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
