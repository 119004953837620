import React, { useEffect, useState } from "react";
import shared from "styles/shared.module.css";
import styles from "./Dashboard.module.css";
import typography from "styles/typography.module.css";
import { getDashboard } from "datasource/Api";
import { DashboardModel } from "datasource/Types";
import { CircularProgress } from "@mui/material";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState<DashboardModel | null>(null);

  useEffect(() => {
    getDashboard().then((res) => {
      setDashboard(res.data);
    });
  }, []);

  if (!dashboard) {
    return (
      <div className={shared.loaderWrapper}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <div className={shared.page}>
      <div className={styles.gradientCard}>
        <div style={{ marginBottom: 15 }} className={typography.title}>
          Increase your revenue by 30%
        </div>
        <div style={{ width: 430 }} className={typography.lightText}>
          Increase your customers' loyalty by using the Deema solution for Buy
          Now Pay Later
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>Deema Quick Stats</div>
        <div className={styles.statsWrapper}>
          <div className={`${shared.card} ${styles.statsCard}`}>
            <div className={styles.statsTitle}>Total revenue</div>
            <div className={styles.statsNumber}>
              {dashboard.total_revenue.toLocaleString("en-US")}{" "}
              <span className={styles.statsCurrency}>{dashboard.currency}</span>
            </div>
          </div>
          <div className={`${shared.card} ${styles.statsCard}`}>
            <div className={styles.statsTitle}>Total users</div>
            <div className={`${styles.statsNumber} ${styles.totalUsers}`}>
              {dashboard.total_users}
            </div>
          </div>
          <div className={`${shared.card} ${styles.statsCard}`}>
            <div className={styles.statsTitle}>Monthly Revenu</div>
            <div className={styles.statsNumber}>
              {dashboard.monthly_revenue.toLocaleString("en-US")}{" "}
              <span className={styles.statsCurrency}>{dashboard.currency}</span>
            </div>
          </div>
          <div className={`${shared.card} ${styles.statsCard}`}>
            <div className={styles.statsTitle}>Daily Revenu</div>
            <div className={styles.statsNumber}>
              {dashboard.daily_revenue.toLocaleString("en-US")}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithCheckPermission(Dashboard, PERMISSIONS.dashboard_statistics);
