import { Box } from "@mui/material";
import Loader from "components/loaders/Loader";
import { Navbar } from "components/navbar";
import NotificationsPopup from "components/notification/useNotificationsPopup";
import { Sidebar } from "components/sidebar";

import { Outlet } from "react-router-dom";
import { useAuthintecated } from "utils/hooks";
import useGetPermissinos from "utils/hooks/useGetPermissinos";

const Layout = () => {
  useAuthintecated();
  const { loading } = useGetPermissinos();
   NotificationsPopup();

  

  return (
    <>
      <Navbar />
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        {loading && <Loader />}
        <Box sx={{ flexGrow: 1 }}>{ <Outlet />}</Box>
      </Box>
    </>
  );
};

export default Layout;
