import React, { FC } from "react";
import typography from "styles/typography.module.css";
import button from "styles/button.module.css";
import { Box } from "@mui/material";

interface CancelModalConfirmProps {
  orderCount: number;
  onConfirm: () => void;
  submitting: boolean;
  confirmed: boolean;
}
const CaptureModalConfirm: FC<CancelModalConfirmProps> = ({
  orderCount,
  onConfirm,
  submitting,
  confirmed,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        gap: 14,
        width: 415,
        marginBottom: 14,
      }}
    >
      {!confirmed ? (
        <>
          <h2
            className={`${typography.title} ${typography.textPrimary}`}
            style={{ fontWeight: 500 }}
          >
            Capture Orders
          </h2>
          <div style={{ fontWeight: 300 }}>
            You are about to Capture {orderCount} orders , are you sure you want
            to Capture the order?
          </div>
          <Box className={typography.regularText} color={"green"}>
            The {orderCount} Orders will be Captured
          </Box>
          <div className={typography.errorText}></div>
          <button
            disabled={submitting}
            onClick={onConfirm}
            style={{ marginTop: 5 }}
            className={`${button.buttonPrimary} ${
              submitting ? button.disabled : ""
            }`}
          >
            Capture Orders
          </button>
        </>
      ) : (
        <>
          <h2
            className={`${typography.title} ${typography.regularText}`}
            style={{ fontWeight: 500 }}
          >
            Captured successfully
          </h2>
          <div style={{ fontWeight: 300 }}>
            The orders has been Captured successfully
          </div>
        </>
      )}
    </div>
  );
};

export default CaptureModalConfirm;
