// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getToken, getMessaging } from "firebase/messaging";
import { setFcmToken } from "datasource/Api";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtPk5GKE0GSjuZiMJXjf3oojUdRUFrPUc",
  authDomain: "deema-2da61.firebaseapp.com",
  projectId: "deema-2da61",
  storageBucket: "deema-2da61.appspot.com",
  messagingSenderId: "1022883754514",
  appId: "1:1022883754514:web:c001b8efbeaae9b7d61ecb",
  measurementId: "G-F24GTR6WFX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log(permission);
  if (permission == "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BJAw-q5kcPXrjNy36rpF5xAxC2Ga5NPjAm1hMUbAHb3VbYEID7XpDAC_XVUcb3gPXCh2hSQDzJUYf13RDVpGSdQ",
    });
    await setFcmToken(token);
  }
};
