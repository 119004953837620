import { PERMISSIONS } from "config/permissions";
import React from "react";
import { useSelector } from "react-redux";

function usePermissionsChecker() {
  const permissinos: string[] = useSelector(
    ({ auth }: { auth: any }) => auth.permissinos
  );
  const hasPermissionTo = (permissionToCheck: string) => {
    return (
      permissionToCheck == PERMISSIONS.none ||
      permissinos?.indexOf(permissionToCheck) >= 0
    );
  };
  return { permissinos, hasPermissionTo };
}

export default usePermissionsChecker;
