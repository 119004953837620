import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import shared from "styles/shared.module.css";
import styles from "./Sattlments.module.css";
import {
  Box,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import {
  SattlmentsRequestFilter,
  SattlmentModel,
  SattlmentStatus,
} from "datasource/Types";
import { getSearchParam, setSearchParam } from "utils";
import { Pagination } from "components/pagination";
import { SattlmentsTable } from "./SattlmentsTable";
import { PER_PAGE_DEFAULT } from "config/base";
import { getSattlments } from "datasource/Api";
import { PERMISSIONS } from "config/permissions";
import WithCheckPermission from "components/WithCheckPermission";
import DateRangeInput from "components/inputs/DateRangeInput";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { getFormatedDate } from "utils/date";

const Sattlments = () => {
  const [sattlmentType, setSattlmentType] = useState<string>("ALL");
  const [date, setDate] = useState<DateRange<dayjs.Dayjs>>([null, null]);
  const [sattlments, setSattlments] = useState<SattlmentModel[]>([]);
  const [defaultPage, setDefaultPage] = useState<number | null>(null);
  const [pagingData, setPagingData] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultPage = parseInt(getSearchParam("page") || "1");
    setDefaultPage(defaultPage);
    setPage(defaultPage);
    getPage(defaultPage);
  }, []);

  const handleSattlmentTypeChanged = (event: SelectChangeEvent) => {
    setSattlmentType(event.target.value);
    getPage(page, {
      from_date: getFormatedDate(date[0]?.toDate()),
      to_date: getFormatedDate(date[1]?.toDate()),
      sattlment_type: event.target.value,
      search,
    });
  };

  const refresh = () => {
    setSattlments([]);
    getPage(page, {
      from_date: getFormatedDate(date[0]?.toDate()),
      to_date: getFormatedDate(date[1]?.toDate()),
      sattlment_type: sattlmentType,
      search,
    });
  };

  const handleDateChanged = (date: DateRange<dayjs.Dayjs>) => {
    setDate(date);
    getPage(page, {
      from_date: getFormatedDate(date[0]?.toDate()),
      to_date: getFormatedDate(date[1]?.toDate()),
      sattlment_type: sattlmentType,
      search,
    });
  };

  const searchApiCall = (value: any) => {
    getPage(page, {
      from_date: getFormatedDate(date[0]?.toDate()),
      to_date: getFormatedDate(date[1]?.toDate()),
      sattlment_type: sattlmentType,
      search: value,
    });
  };

  const handleSearchChanged = useCallback((event: any) => {
    const search = event.target.value;
    setSearch(search);
    getPage(page, {
      from_date: getFormatedDate(date[0]?.toDate()),
      to_date: getFormatedDate(date[1]?.toDate()),
      sattlment_type: sattlmentType,
      search,
    });
  }, []);

  const getPage = (page: number, filters?: SattlmentsRequestFilter) => {
    setLoading(true);
    if (filters?.sattlment_type === "ALL") {
      delete filters.sattlment_type;
    }
    getSattlments({
      pagingData: {
        page,
        perPage: PER_PAGE_DEFAULT,
      },
      filters,
    })
      .then((res) => {
        const { data: items, ...pagingData } = res.data;
        setSattlments(items);
        setPagingData({ ...pagingData, count: items.length });
        setSearchParam({ paramName: "page", paramValue: page });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const pageChanged = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    getPage(page);
  };

  return (
    <div className={shared.page}>
      <div className={shared.pageTitle}>All Settlements</div>
      <div className={styles.filters}>
        <TextField
          sx={{ flex: 1 }}
          label="Search by key words"
          variant="outlined"
          placeholder="Search by sattlment id"
          value={search}
          size="small"
          onChange={handleSearchChanged}
          InputProps={{
            endAdornment: <SearchIcon style={{ color: "grey" }} />,
          }}
        />
        <DateRangeInput value={date} onChange={handleDateChanged} />

        <Select
          style={{ width: 200 }}
          labelId="Settlement type"
          size="small"
          id="demo-simple-select"
          onChange={handleSattlmentTypeChanged}
          value={sattlmentType}
        >
          <MenuItem value={"ALL"}>All Settlements</MenuItem>
          <MenuItem value={SattlmentStatus.SATTLED}>Sattled</MenuItem>
          <MenuItem value={SattlmentStatus.PENDING}>Pending</MenuItem>
        </Select>
      </div>
      <div className={shared.spacer} />
      <Paper sx={{ padding: "20px" }}>
        <SattlmentsTable
          loading={loading}
          sattlments={sattlments}
          refresh={refresh}
        />
        <Box className={styles.paging}>
          <div className={styles.showingResults}>
            {pagingData?.count} results out of {pagingData?.total}
          </div>
          {defaultPage && (
            <Pagination
              defaultPage={defaultPage}
              count={pagingData?.last_page || 0}
              onChange={pageChanged}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default WithCheckPermission(Sattlments, PERMISSIONS.view_settlement);
