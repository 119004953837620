import React, { FC, useState } from "react";
import {
  SattlmentModel,
  SattlmentType,
  SattlmentStatus,
} from "datasource/Types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { dateFormatter, formatAMPM } from "utils";
import styles from "./Sattlments.module.css";
import { CircularProgress, Collapse, IconButton, TableContainer, Typography } from "@mui/material";
import shared from "styles/shared.module.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TransactionDetailsPopup from "./TransactionDetailsPopup";

type SattlmentsTableProps = {
  sattlments: SattlmentModel[];
  refresh: () => void;
  loading: boolean;
};

export const SattlmentsTable: FC<SattlmentsTableProps> = ({
  sattlments,
  refresh,
  loading,
}) => {
  const [submiting, setSubmitting] = useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: 6,
    color: "var(--color-text)",
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 700,
      paddingBottom: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "1px #E3E4ED solid",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: "#F7F8FA",
    // },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  const StyledExpandableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "none",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: "#F7F8FA",
    // },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  const tagClass = (type: string) => {
    switch (type) {
      case SattlmentType.LOCAL: {
        return styles.yellow;
      }
      case SattlmentStatus.PENDING: {
        return styles.yellow;
      }
      case SattlmentStatus.SATTLED: {
        return styles.green;
      }

      default: {
        return styles.yellow;
      }
    }
  };

  function SattleRow({ row }: any) {
    const [open, setOpen] = React.useState(false);
    const [openTransaction, setOpenTransaction] = React.useState(false);

    return (
      <>
        <StyledTableRow key={row.id}>
          <StyledTableCell align="left">{row.id}</StyledTableCell>
          <StyledTableCell align="left">
            {dateFormatter(row.requested_at)}
          </StyledTableCell>
          <StyledTableCell align="left">{row.wallet}</StyledTableCell>
          <StyledTableCell align="left">
            <div
              className={tagClass(row.settlement_type)}
              style={{ textTransform: "capitalize" }}
            >
              {row.settlement_type}
            </div>
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.amount} {row.wallet}
          </StyledTableCell>
          <StyledTableCell align="left">
            <div
              className={tagClass(row.status)}
              style={{ textTransform: "capitalize" }}
            >
              {row.status}
            </div>
          </StyledTableCell>
          <StyledTableCell align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
        <StyledExpandableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className={styles.collapsWrapper}>
                <div className={styles.metaDataWrapper}>
                  <div className={styles.title}>
                    Count of settlement purchases
                  </div>
                  <ul>
                    <li className={styles.purple}>
                      ({row.completed_settlements}) completed payments
                    </li>
                    <li className={styles.red}>
                      ({row.cancellation}) cancelled payments
                    </li>
                  </ul>
                </div>
                <div>
                  <div className={styles.title}>
                    Count of settlement purchases
                  </div>
                  <div>{dateFormatter(row.last_settlement_date)}</div>
                  <div className={styles.thin}>
                    {formatAMPM(row.last_settlement_date)}
                  </div>
                </div>
                <div>
                  <div className={styles.title}>Settlement details</div>
                  <div className={styles.invoiceWrapper}>
                    <div className={styles.invoiceRow}>
                      <span className={styles.invoiceLabel}>Total Purchases</span>
                      <span>{row.total_purchases_amount} {row.wallet}</span>
                    </div>


                    <div className={styles.invoiceRow}>
                      <span className={styles.invoiceLabel}>Total Returned Share</span>
                      <span>{row.total_returned_share_amount} {row.wallet}</span>
                    </div>

                    <div className={styles.seperator}/>

                    <div className={`${styles.red} ${styles.invoiceRow}`}>
                      <span className={styles.invoiceLabel}>Refund</span>
                      <span>{row.total_refund_amount} {row.wallet}</span>
                    </div>
                    <div className={`${styles.red} ${styles.invoiceRow}`}>
                      <span className={styles.invoiceLabel}>Cancellation</span>
                      <span>{row.total_cancellation_amount} {row.wallet}</span>
                    </div>

                    <div className={`${styles.red} ${styles.invoiceRow}`}>
                      <span className={styles.invoiceLabel}>Fixed fees</span>
                      <span>{row.fee} {row.wallet}</span>
                    </div>

                    <div className={`${styles.red} ${styles.invoiceRow}`}>
                      <span className={styles.invoiceLabel}>Share</span>
                      <span>{row.total_shares_amount} {row.wallet}</span>
                    </div>

                    <div style={{fontSize: 16}} className={styles.invoiceRow}>
                      <strong className={styles.invoiceLabel}>Amount</strong>
                      <strong>{row.amount} {row.wallet}</strong>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.title}>
                    <Typography onClick={() => setOpenTransaction(true)} sx={{ cursor: "pointer" }} color={theme => theme.palette.primary.main}>  Show  Details {">"}</Typography>
                    {openTransaction && <TransactionDetailsPopup settlementId={row.id} open={openTransaction} handleClose={() => setOpenTransaction(false)} />}

                  </div>

                </div>
              </div>
            </Collapse>
          </TableCell>
        </StyledExpandableRow>
      </>
    );
  }

  return (
      <>
        <TableContainer>
          {!loading && (
              <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">ID</StyledTableCell>
                    <StyledTableCell align="left">Settlement date </StyledTableCell>
                <StyledTableCell align="left">Wallet</StyledTableCell>
                <StyledTableCell align="left">Settlement Type</StyledTableCell>
                <StyledTableCell align="left">Net Amount</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sattlments.map((row) => (
                <SattleRow row={row} />
              ))}
            </TableBody>
          </Table>
        )}

        {loading && (
          <div className={shared.loaderWrapper}>
            <CircularProgress color="inherit" />
          </div>
        )}
      </TableContainer>
    </>
  );
};
