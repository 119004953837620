import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import shared from "styles/shared.module.css";
import { Box, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  OrderesRequestFilter,
  WalletsModel,
} from "datasource/Types";
import { getSearchParam, setSearchParam } from "utils";
import { Pagination } from "components/pagination";
import { WalletsTable } from "./WalletsTable";
import { PER_PAGE_DEFAULT } from "config/base";
import { getWallets } from "datasource/Api";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
const Wallets = () => {
  const [wallets, setWallets] = useState<WalletsModel[]>([]);



  const [defaultPage, setDefaultPage] = useState<number | null>(null);
  const [pagingData, setPagingData] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultPage = parseInt(getSearchParam("page") || "1");
    setDefaultPage(defaultPage);
    setPage(defaultPage);
    getPage(defaultPage);
  }, []);

  const handleSearchChanged = useCallback((event: any) => {
    const search = event.target.value;
    setSearch(search);
    getPage(page, {
      search,
    });
  }, []);

  const getPage = (page: number, filters?: OrderesRequestFilter) => {
    if (filters?.order_status === "ALL") {
      delete filters.order_status;
    }
      setLoading(true);
      getWallets({
        pagingData: {
          page,
          perPage: PER_PAGE_DEFAULT,
        },
        filters,
      })
        .then((res) => {
          const { data: items, ...pagingData } = res.data;
          setWallets(items);
          setPagingData({ ...pagingData, count: items.length });
          setSearchParam({ paramName: "page", paramValue: page });
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const pageChanged = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    getPage(page);
  };
  

  return (
    <div className={shared.page}>
      <div className={shared.pageTitle}>All Wallets </div>
      <Box sx={{ display: "flex", gap: 10 }}>
        {" "}
        <TextField
          sx={{ flex: 1 }}
          label="Search by key words"
          variant="outlined"
          placeholder="Search by merchant,currency ..."
          value={search}
          size="small"
          onChange={handleSearchChanged}
          InputProps={{
            endAdornment: <SearchIcon style={{ color: "grey" }} />,
          }}
        />
       
      </Box>
      <div className={shared.spacer} />
      <Paper sx={{ padding: "20px" }}>
        <WalletsTable loading={loading} wallets={wallets} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div className={shared.showingResults}>
            {pagingData?.count} results out of {pagingData?.total}
          </div>
          {defaultPage && (
            <Pagination
              defaultPage={defaultPage}
              count={pagingData?.last_page || 0}
              onChange={pageChanged}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default WithCheckPermission(Wallets, PERMISSIONS.view_transaction);
