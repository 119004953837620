export const dateFormatter = (textDate: string | undefined) => {
  if (!textDate) return "";
  const date = new Date(textDate);
  return date.toLocaleDateString("en-US", { dateStyle: "long" });
};

export const TEXT_NUMBERS = ["zero", "one", "tow", "three", "four", "five", "six"];

export const downloadBinary = (path:any, outputName:string) => {
  var a = document.createElement('a');
  a.href = path;
  a.download = outputName;
  a.target = "_blank"
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
const months = [
  "Jan", "Feb", "Mar", "Apr",
  "May", "Jun", "Jul", "Aug",
  "Sep", "Oct", "Nov", "Dec"
];
export const dateFormatterWithMonthName=(inputDateString:string)=>{
  
  const inputDate = new Date(inputDateString);

  // Extract year, month, and day from the Date object
  const year = inputDate.getUTCFullYear();
  const monthIndex = inputDate.getUTCMonth();
  const day = inputDate.getUTCDate();
  
  // Format the date in the desired format
  const formattedDate = `${day.toString().padStart(2, '0')}. ${
    months[monthIndex]
  } . ${year}`;
  return formattedDate
}
export const delayedRedirect = (link: string, ms: number) => {
  setTimeout(() => {
    window.location.href = link;
  }, ms);
};

export const roundForFirstDecemial = (val: number) => {
  return Math.round(val * 10) / 10;
};

export const isToday = (textDate: string) => {
  const date = new Date(textDate);
  return date.toDateString() === new Date().toDateString();
};

export const setSearchParam = ({
  paramName,
  paramValue,
}: {
  paramName: string;
  paramValue: any;
}) => {
  var url = new URL(window.location.href);
  url.searchParams.set(paramName, paramValue);
  window.history.pushState({}, "", url.href);
};

export const getSearchParam = (paramName: string) =>
  new URL(window.location.href).searchParams.get(paramName);

export const formatAMPM = (textDate: string) => {
  if (!textDate) return "";
  const date = new Date(textDate);
  var hours = date.getHours();
  var minutes: string | number = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

/**
 * Validate if url string is a url
 *
 * Stack over flow: https://stackoverflow.com/a/5717133
 *
 * @param url string url
 * @returns
 */
export const validURL = (url: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
};

const currencyCountryMap = new Map<string, string>([
  ["AED", "UAE"],
  ["KWD", "KWT"],
  ["BHD", "BHR"],
]);

export const currencyCountry = (currency?: string) => {
  if(!currency) return;
  return currencyCountryMap.get(currency.toUpperCase());
};
