import { CheckBox, Edit } from "@mui/icons-material";
import { Box,  Stack, Typography } from "@mui/material";
import { RoleModel } from "datasource/Types";
import { Link } from "react-router-dom";

function RoleCard({ role }: RoleCardType) {
  return (
    <Box sx={{ p: 4, borderRadius: 4, border: 1, height: "100%" }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        {" "}
        <Typography fontWeight={700} variant="h6" color={"primary"}>
          {role.name}
        </Typography>
        {!role.is_default && (
          <Link to={`/roles/${role.id}`}>
            <Edit color="primary" />
          </Link>
        )}
      </Stack>
      <Stack mt={2} gap={3}>
        {role?.permissions?.map((permission) => (
          <Stack
            key={permission.id}
            direction="row"
            alignItems={"center"}
            gap={1}
          >
            <CheckBox color="primary" fontSize="medium"></CheckBox>

            <div>
              <Typography fontWeight={'600'}  variant="body1">{permission.name}</Typography>
              <Typography variant="caption">
                {permission.description}
              </Typography>
            </div>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}

export default RoleCard;
type RoleCardType = {
  role: RoleModel;
};
