import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  Stack,
  Checkbox,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import shared from "styles/shared.module.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Loader from "components/loaders/Loader";
import { CreateRoleType } from "datasource/Types";
import { useQuery } from "@tanstack/react-query";
import { getPermissions } from "datasource/Api";
function RoleForm({
  initialValues,
  handleSubmit,
  isLoading,
  title,
}: {
  initialValues: CreateRoleType;
  title: string;
  isLoading: boolean;
  handleSubmit: (values: CreateRoleType) => void;
}) {
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    permissions: Yup.array().required("required").min(1),
  });
  const { data: permissionsData, isLoading: isLodingPermissions } = useQuery({
    queryKey: ["permissions"],
    queryFn: () => getPermissions(),
  });

  return (
    <div className={shared.page}>
      {(isLoading || isLodingPermissions) && <Loader />}
      <Box>
        <Typography variant="h5" mb={5} fontWeight="700">
          {title}
        </Typography>
        <Formik
          validateOnChange={false}
          validateOnBlur
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <Grid spacing={3} container>
                <Grid item xs={12} lg={5}>
                  {" "}
                  <Grid spacing={3} container mb={4}>
                    <Grid item xs={12}>
                      <TextField
                        {...props.getFieldProps({ name: "name" })}
                        variant="outlined"
                        error={!!props.errors.name}
                        fullWidth
                        label="role  name "
                        name="name"
                      />
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {props.errors.name}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          border: 1,
                          p: 2,
                          py: 2,
                          borderRadius: 1,
                          borderColor: "lightgrey",
                          m: 0,
                        }}
                      >
                        {" "}
                        <FormControl component="fieldset">
                          <FormLabel
                            sx={{ mb: 2, fontSize: 20, fontWeight: "500" }}
                            component="legend"
                          >
                            permissions :
                          </FormLabel>
                          <Stack gap={2}>
                            {permissionsData?.data.map((permission) => (
                              <FormControlLabel
                                value="end"
                                sx={{
                                  m: 0,
                                }}
                                control={
                                  <Checkbox
                                    onChange={(e, checked) => {
                                      if (checked) {
                                        props.setFieldValue("permissions", [
                                          ...props.values.permissions,
                                          permission.id,
                                        ]);
                                        return;
                                      }
                                      props.setFieldValue(
                                        "permissions",
                                        props.values.permissions.filter(
                                          (item) => item != permission.id
                                        )
                                      );
                                    }}
                                    checked={
                                      !!props.values.permissions.find(
                                        (item) => item == permission.id
                                      )
                                    }
                                  />
                                }
                                label={
                                  <>
                                    <Typography>{permission.name}</Typography>
                                    <Typography variant="caption">
                                      {permission.description}
                                    </Typography>
                                  </>
                                }
                                labelPlacement="end"
                              />
                            ))}
                          </Stack>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        sx={{ boxShadow: "none" }}
                        size="large"
                        variant="contained"
                      >
                        save role
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default RoleForm;
