import { Box, CircularProgress, Dialog, MenuItem, Select } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getSettlementsDetails } from "datasource/Api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import shared from "styles/shared.module.css";

import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { dateFormatter, formatAMPM } from "utils";
function TransactionDetailsPopup({
  handleClose,
  open,
  settlementId,
}: TransactionDetailsPopupPropsType) {
  const [transactionType, setTransactionType] = useState<string>("All");
  const [transactionAction, setTransactionAction] = useState<string>("All");

  const { data, isLoading } = useQuery({
    queryKey: [
      "TransactionDetails",
      settlementId,
      transactionType,
      transactionAction,
    ],
    queryFn: () =>
      getSettlementsDetails({
        settlementId,
        filters: {
          transaction_type: transactionType,
          transaction_action: transactionAction,
        },
      }),
  });

  const TRANSACTION_TYPES = ["credit", "debit"];
  const TRANSACTION_ACTIONS = [
    "purchase",
    "cancellation",
    "share",
    "bnpl_fee",
    "share_returned",
  ];


  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={handleClose}>
      <Box sx={{ p: 2 }}>
        <Box display="flex" gap={3} alignItems={"center"} mb={4}>
          <Select
            style={{ width: 200 }}
            labelId="TRANSACTION_TYPES"
            size="small"
            id="demo-simple-select"
            onChange={(e) => {
              setTransactionType(e.target.value);
            }}
            value={transactionType}
          >
            <MenuItem defaultChecked value={"All"}>
              All Types
            </MenuItem>
            {TRANSACTION_TYPES.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </Select>
          <Select
            style={{ width: 200 }}
            labelId="Settlement type"
            size="small"
            id="demo-simple-select"
            onChange={(e) => {
              setTransactionAction(e.target.value);
            }}
            value={transactionAction}
          >
            <MenuItem defaultChecked value={"All"}>
              All Actions
            </MenuItem>
            {TRANSACTION_ACTIONS.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </Select>
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Transaction Amount</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Transaction Action</TableCell>
                <TableCell>Purchase ID</TableCell>
                <TableCell>Transaction Date-time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.map((row: any) => (
                <TableRow
                  sx={{ cursor: "pointer" }}
                  key={row.transaction_id}
                  onDoubleClick={() =>
                    window.open(`/orders/${row.purchase_id}`, "_blank")
                  }
                >
                  <TableCell component="th" scope="row">
                    {row.transaction_id}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: row.transaction_type == "credit" ? "green" : "red",
                    }}
                  >
                    {row.transaction_amount}
                  </TableCell>
                  <TableCell>{row.transaction_type}</TableCell>
                  <TableCell>{row.transaction_action}</TableCell>
                  <TableCell
                    sx={{ textDecoration: "underline", fontWeight: "bold" }}
                    onClick={() =>
                      window.open(`/orders/${row.purchase_id}`, "_blank")
                    }
                  >
                    {row.purchase_id}
                  </TableCell>
                  <TableCell>
                    <div>
                      {dateFormatter(row?.transaction_datetime)}
                    </div>
                    <div>
                      {formatAMPM(row?.transaction_datetime)}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isLoading && (
            <div className={shared.loaderWrapper}>
              <CircularProgress color="inherit" />
            </div>
          )}
        </TableContainer>
      </Box>
    </Dialog>
  );
}

export default TransactionDetailsPopup;
type TransactionDetailsPopupPropsType = {
  open: boolean;
  handleClose: () => void;
  settlementId: number;
};
