import * as React from "react";
import { FC, ReactNode } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styles from "./modal.module.css";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  closeText?:string;
  children: ReactNode;
  closeOnClickOutside?: boolean
}

const BasicModal: FC<ModalProps> = ({
  open,
  onClose,
  title = "",
  children,
  closeText,
  closeOnClickOutside = false
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      onClose={() => {
        if(closeOnClickOutside) {
          onClose()
        }
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          sx: {
            backdropFilter: "blur(2px)",
            background: "rgba(0 0 0 / 0.2)",
          },
        },
      }}
    >
      <Fade in={open}>
        <Box className={styles.modalBox}>
          {title && <div className={styles.title}>{title}</div>}
          {children}
          {closeText && <div className={styles.closeText}><span onClick={onClose}>{closeText}</span></div>}
        </Box>
      </Fade>
    </Modal>
  );
};

export default BasicModal;
