import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createRole } from "datasource/Api";
import {CreateRoleType } from "datasource/Types";
import RoleForm from "./RoleForm";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
function CreateRole() {
  const navigate = useNavigate();
  const { isPending, mutate } = useMutation({
    mutationFn: createRole,

    onSuccess(data) {
      toast.success("new role was created successfully", {
        autoClose: 2000,
        position: "bottom-right",
      });
      navigate("/roles");
    },
    onError(error) {
      toast.error("something wrong , maybe the roles name is taken ", {
        autoClose: 2000,
        position: "bottom-right",
      });
    },
  });
  const initialValues = {
    name: "",
    permissions: [],
  };
  const handleSubmit = (values: CreateRoleType) => {
    mutate(values);
  };

  return (
    <RoleForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isLoading={isPending}
      title="Create new Role"
    />
  );
}

export default WithCheckPermission(CreateRole, PERMISSIONS.role_management);
