import { CircularProgress } from "@mui/material";
import { fetcUserData } from "datasource/Api";
import { MerchantStatus } from "datasource/Types";
import { Dashboard } from "pages/dashboard";
import { Registration } from "pages/registration";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { logout, persistUser } from "store/authReducer";
import shared from "styles/shared.module.css";
import { useDispatch } from "react-redux";
import usePermissionsChecker from "utils/hooks/usePermissionsChecker";
import { PERMISSIONS } from "config/permissions";

const Home = () => {
  const dispatch = useDispatch();

  const approvedMerchant =
    (useSelector(({ auth }: { auth: any }) => auth.user?.status) || null) ===
    MerchantStatus.APPROVED;

  const [userUpdated, setUserUpdated] = useState(false);

  useEffect(() => {
    if (!approvedMerchant) {
      fetcUserData().then((res) => {
        setUserUpdated(true);
        if (res.status === MerchantStatus.REJECTED) {
          dispatch(logout());
        }
        dispatch(persistUser(res));
      });
    }
  }, []);
  const { hasPermissionTo } = usePermissionsChecker();
  if (!approvedMerchant && !userUpdated) {
    return (
      <div className={shared.page}>
        <div className={shared.loaderWrapper}>
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  return approvedMerchant ? (
    hasPermissionTo(PERMISSIONS.dashboard_statistics) ? (
      <Dashboard />
    ) : (
      <></>
    )
  ) : (
    <Registration />
  );
};

export default Home;
