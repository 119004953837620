import {
  InitiallyApprovedMerchant,
  PendingMerchant,
} from "components/registrationStep";
import { MerchantStatus } from "datasource/Types";
import React from "react";
import { useSelector } from "react-redux";

const Registration = () => {
  const merchantStatus = useSelector(
    ({ auth }: { auth: any }) => auth.user?.status
  );

  const registrationStep = () => {
    switch (merchantStatus) {
      case MerchantStatus.INITIAL_APPROVED:
        return <InitiallyApprovedMerchant />;
      case MerchantStatus.PENDING:
        return <PendingMerchant />;
    }
  };

  return <>{registrationStep()}</>;
};

export default Registration;
