import React, { FC, useEffect, useState } from "react";
import shared from "styles/shared.module.css";
import typography from "styles/typography.module.css";
import styles from "./webhook.module.css";
import button from "styles/button.module.css";
import { CircularProgress, TextField } from "@mui/material";
import { getWebhookInfo, updateWebhookInfo } from "datasource/Api";
import { validURL } from "utils";
import { toast } from "react-toastify";
import { PERMISSIONS } from "config/permissions";
import WithCheckPermission from "components/WithCheckPermission";

const Webhook: FC = () => {
  const [webhookUrl, setWebhookUrl] = useState<string | null>(null);
  const [headerKey, setHeaderKey] = useState("");
  const [headerValue, setHeaderValue] = useState("");
  const [infoFetched, setInfoFetched] = useState(false)
  const [formErrors, setFormError] = useState({
    webhookUrl: false,
    headerKey: false,
    headerValue: false,
  });
  const [submitting, setSubmitting] = useState(false)



  useEffect(() => {
    getWebhookInfo().then((res) => {
      if(res.data.webhook_url) {
        res.data.webhook_url = res.data.webhook_url.replace("https://", "")
      }
      setWebhookUrl(res.data.webhook_url)
      setHeaderKey(res.data.header_key)
      setHeaderValue(res.data.header_value)
    })
    .then(() => {
      setInfoFetched(true)
    })
    ;
  }, []);

  const validateWebhookUrl = (webhookUrl: string | null) => {
    let valid = true;
    if (webhookUrl) {
      valid = validURL(webhookUrl);
    }

    setFormError({
      ...formErrors,
      webhookUrl: !valid,
    });

    return valid;
  };

  const validateHeaderKey = (headerKey: string) => {
    let valid = true;

    if (headerKey) {
      valid = Boolean(headerKey.match(/^[A-Za-z_\-]*$/));
    }

    setFormError({
      ...formErrors,
      headerKey: !valid,
    });

    return valid;
  };

  const handleHeaderValueChange = (event: any) => {
    const headerValue = event.target.value;
    setFormError({
      ...formErrors,
      headerValue: false,
    });
    setHeaderValue(headerValue);
  };

  const handleHeaderKeyChasnge = (event: any) => {
    const headerKey = event.target.value;
    validateHeaderKey(headerKey);
    setHeaderKey(headerKey);
  };

  const handleWebhookUrlChange = (event: any) => {
    const webhookUrl = event.target.value;
    validateWebhookUrl(webhookUrl);
    setWebhookUrl(webhookUrl);
  };

  const validateForm = () => {
    let valid = validateHeaderKey(headerKey) && validateWebhookUrl(webhookUrl);

    if (!((!headerKey && !headerValue) || (headerKey && headerValue))) {
      valid = false;
      if (!headerKey) {
        setFormError({
          ...formErrors,
          headerKey: true,
        });
      } else {
        setFormError({
          ...formErrors,
          headerValue: true,
        });
      }
    }
    return valid;
  };

  const save = () => {
    if (!webhookUrl || submitting || !validateForm()) {
      return;
    }
    setSubmitting(true)
    updateWebhookInfo({
      webhookUrl: "https://" + webhookUrl,
      headerKey,
      headerValue,
    }).then((res) => {
      toast.success("Webhook info updated successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }).finally(() => {
      setSubmitting(false)
    })
  };

  if (!infoFetched) {
    return (
      <div className={shared.page}>
        <div className={shared.pageTitle}>Webhook</div>
        <div className={shared.loaderWrapper}>
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  return (
    <div className={shared.page}>
      <div className={shared.pageTitle}>Webhook</div>
      <div className={styles.wrapper}>
        <div className={typography.thick}>Webhook information</div>
        <div className={typography.small}>
          Create the webhook by providing the information
        </div>
        <TextField
        disabled={submitting}
          onChange={handleWebhookUrlChange}
          error={formErrors.webhookUrl}
          defaultValue={webhookUrl}
          helperText={
            formErrors.webhookUrl ? "Webhook url should be a valid url" : ""
          }
          InputProps={{
            sx: { borderRadius: "7px" },
            startAdornment: <div style={{ marginRight: "5px" }}>https://</div>,
          }}
          size="small"
          label="webhook url"
        />
        <div
          style={{ marginTop: 10, marginBottom: 10 }}
          className={shared.devider}
        ></div>
        <div className={typography.thick}>Security Header</div>
        <div className={styles.fieldsWrapper}>
          <TextField
        disabled={submitting}
        defaultValue={headerKey}
          InputProps={{
              sx: { borderRadius: "7px" },
            }}
            style={{ flex: 1 }}
            onChange={handleHeaderKeyChasnge}
            error={formErrors.headerKey}
            helperText={
              formErrors.headerKey
                ? headerValue
                  ? "Please enter header key"
                  : "Header key should not have special charecters"
                : ""
            }
            size="small"
            label="key"
          />
          <TextField
        disabled={submitting}
        defaultValue={headerValue}
          InputProps={{
              sx: { borderRadius: "7px" },
            }}
            error={formErrors.headerValue}
            helperText={
              formErrors.headerValue
                ? headerKey
                  ? "Please enter random string to be as your header value"
                  : ""
                : ""
            }
            onChange={handleHeaderValueChange}
            style={{ flex: 1 }}
            size="small"
            label="value"
          />
        </div>
        <button
          onClick={save}
          style={{ marginTop: 20 }}
          className={`${button.buttonPrimary} ${
            !webhookUrl || formErrors.webhookUrl || submitting ? button.disabled : ""
          }`}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default WithCheckPermission(Webhook, PERMISSIONS.account_configuration);
