import React from "react";
import styles from "./SubmittedFile.module.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CancelIcon from "@mui/icons-material/Cancel";

type TProps = {
  file: any;
  cancel: () => void;
  accepted?: boolean;
  rejected?: boolean;
  readonly?: boolean;
  download?: () => void
};

export const SubmittedFile = ({
  file,
  cancel,
  accepted,
  rejected,
  readonly,
  download
}: TProps) => {

  return (
    <div
      className={`${styles.wrapper} ${accepted && styles.success} ${
        rejected && styles.error
      }`}
    >
      <div className={styles.docInfo}>
        <i className={styles.pdfIcon} />
        <div>
          <div>{file.name}</div>
          <div className={styles.metaData}>
            <span>{dateFormatter(file.lastModifiedDate)}</span>
            {file.size && <span>{formatBytes(file.size)}</span>}
          </div>
        </div>
      </div>
      <div className={styles.controls}>
        {!readonly && (
          <CancelIcon className={styles.clickable} onClick={cancel} />
        )}

        {file && (
          <DownloadForOfflineIcon
            className={styles.clickable}
            onClick={download}
          />
        )}
      </div>
    </div>
  );
};

function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const dateFormatter = (textDate: string | undefined) => {
  if (!textDate) return "";
  const date = new Date(textDate);
  return date.toLocaleDateString("en-US", { dateStyle: "long" });
};
