import React, { FC, useState } from "react";
import { PaymentPlan, OrderStatus, OrderModel } from "datasource/Types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { currencyCountry, dateFormatter, formatAMPM } from "utils";
import styles from "./orders.module.css";
import { Button, CircularProgress, Stack, TableContainer } from "@mui/material";
import shared from "styles/shared.module.css";
import { BasicModal } from "shared/modal";
import { CancelModalConfirm, RefundModal } from "components/modals";
import { cancelOrderRequest, refundOrderRequest } from "datasource/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import usePermissionsChecker from "utils/hooks/usePermissionsChecker";
import { PERMISSIONS } from "config/permissions";
export const tagClass = (purchaseStatus: string) => {
  switch (purchaseStatus) {
    case OrderStatus.CAPTURED: {
      return styles.captured;
    }
    case OrderStatus.CANCELLED: {
      return styles.cancelled;
    }
    case OrderStatus.EXPIRED: {
      return shared.bast;
    }
    case OrderStatus.PENDING: {
      return styles.pending;
    }
    case PaymentPlan.ONE_PAYMENT: {
      return styles.onePayment;
    }
    case PaymentPlan.THREE_PAYMENTS: {
      return styles.theePayments;
    }
    default: {
      return styles.captured;
    }
  }
};
type OrderesTableProps = {
  orders: OrderModel[];
  refresh: () => void;
  loading: boolean;
};

export const OrdersTable: FC<OrderesTableProps> = ({
  orders,
  refresh,
  loading,
}) => {
  const [cancelModal, setCancelModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel | null>(null);
  const [submiting, setSubmitting] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [refundSuccess, setRefundSuccess] = useState(false);
  const [redundModal, setRefundModal] = useState(false);
  const { hasPermissionTo } = usePermissionsChecker();




  const confirmCancellation = (purchaseId: number) => {
    setSubmitting(true);
    cancelOrderRequest(purchaseId)
      .then(() => {
        setCancelSuccess(true);
      })
      .catch(() => {
        toast.error("Unexpected error, please try again", {
          position: "top-right",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const confirmRefund = (purchaseId: number, refundAmount: number) => {
    setSubmitting(true);
    refundOrderRequest(purchaseId, refundAmount)
      .then(() => {
        setRefundSuccess(true);
      })
      .catch(() => {
        toast.error("Unexpected error, please try again", {
          position: "top-right",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const closeCancelModal = () => {
    setCancelModal(false);
    if (cancelSuccess) {
      refresh();
    }
    setTimeout(() => {
      setCancelSuccess(false);
      setSelectedOrder(null);
    }, 500);
  };

  const closeRefundModal = () => {
    setRefundModal(false);
    if (refundSuccess) {
      refresh();
    }
    setTimeout(() => {
      setRefundSuccess(false);
      setSelectedOrder(null);
    }, 500);
  };
  const navigate = useNavigate();

  return (
    <>
      <TableContainer>
        {!loading && (
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Order ID</TableCell>
                <TableCell align="left">Deema Order ID</TableCell>
                <TableCell align="left">Date and time</TableCell>
                <TableCell align="left">Order Status</TableCell>
                <TableCell align="left">Payment Plan</TableCell>
                <TableCell align="left">Customer Info</TableCell>
                <TableCell align="left">branch</TableCell>
                <TableCell align="left">Amount{"(s)"}</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((row) => (
                <TableRow
                  key={row.id}
                  onDoubleClick={() => navigate(`/orders/${row.id}`)}
                >
                 
                  <TableCell align="left">
                    {row.merchant_order_id}
                  </TableCell>
                  <TableCell align="left">
                    {row.id}
                  </TableCell>
                  <TableCell align="left">
                    <div>{dateFormatter(row.order_date_time)}</div>
                    <div className={styles.thin}>
                      {formatAMPM(row.order_date_time)}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div
                      className={tagClass(row.status)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.status}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    {
                      <div className={tagClass(row.payment_plan)}>
                        {row.payment_plan}
                      </div>
                    }
                  </TableCell>
                  <TableCell align="left">
                    <div>{row.customer_name}</div>
                    <div className={styles.thin}>
                      {row.customer_phone_number ? "+" : ""}
                      {row.customer_phone_number}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    {row.branch_name}
                  </TableCell>
                  <TableCell align="left">
                    {row.amount} {row.currency_code}
                  </TableCell>
                  <TableCell align="left">
                    <Stack direction="row" gap={1}>
                      {row.status == OrderStatus.CAPTURED && (
                        <>
                          {hasPermissionTo(PERMISSIONS.cancel_purchase) && (
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() => {
                                setSelectedOrder(row);
                                setCancelModal(true);
                              }}
                            >
                              {" "}
                              Cancel
                            </Button>
                          )}
                          {hasPermissionTo(PERMISSIONS.refund_purchase) && (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => {
                                setSelectedOrder(row);
                                setRefundModal(true);
                              }}
                            >
                              {" "}
                              refund
                            </Button>
                          )}
                        </>
                      )}{" "}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {loading && (
          <div className={shared.loaderWrapper}>
            <CircularProgress color="inherit" />
          </div>
        )}
      </TableContainer>
      {selectedOrder && (
        <BasicModal
          open={cancelModal}
          closeOnClickOutside
          closeText={cancelSuccess ? "Ok" : "Don't cancel"}
          onClose={closeCancelModal}
        >
          <CancelModalConfirm
            confirmed={cancelSuccess}
            submitting={submiting}
            onConfirm={() => confirmCancellation(selectedOrder.id)}
            wallet={`${currencyCountry(selectedOrder?.currency_code)} - ${
              selectedOrder?.currency_code
            }`}
            customerName={selectedOrder?.customer_name}
            orderId={selectedOrder.merchant_order_id}
          />
        </BasicModal>
      )}
      {selectedOrder && (
        <BasicModal
          open={redundModal}
          closeOnClickOutside
          closeText={refundSuccess ? "Ok" : "Cancel"}
          onClose={closeRefundModal}
        >
          <RefundModal
            confirmed={refundSuccess}
            submitting={submiting}
            maxRefund={selectedOrder.amount}
            currency={selectedOrder.currency_code}
            onConfirm={(refundAmount) =>
              confirmRefund(selectedOrder.id, refundAmount)
            }
            wallet={`${currencyCountry(selectedOrder.currency_code)} - ${
              selectedOrder.currency_code
            }`}
            orderId={selectedOrder.merchant_order_id}
          />
        </BasicModal>
      )}
    </>
  );
};
