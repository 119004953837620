import { useEffect } from "react";
import { toast } from "react-toastify";
import { onMessage } from "firebase/messaging";
import { generateToken, messaging } from "./firebase";
import { Typography } from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";
function useNotificationsPopup() {
 
  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {

      toast.info(
        <>
          <a
            rel="noreferrer"
            style={{ textDecoration: "none" }}
            target="_blank"
            href={`/orders/${payload?.data?.purchase_id}`}
          >
            <Typography fontWeight={600} variant="body1">
              {payload.notification?.title}
            </Typography>
            <Typography variant="body2">
              {payload.notification?.body}
            </Typography>
          </a>
        </>,

        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          icon: <SellIcon sx={{ color: "#faa616" }} />,
          pauseOnHover: true,
          draggable: true,

          progress: undefined,
          theme: "light",
        }
      );
    });
  }, []);
 
}
export default useNotificationsPopup;
