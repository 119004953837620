import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./DropZone.module.css";

type TProps = {
  submitFile: (file: any) => void;
};

export const DropZone = ({ submitFile }: TProps) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    submitFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxSize: 1024 * 1024 * 100 });

  return (
    <div {...getRootProps()} className={styles.wrapper}>
      <input {...getInputProps()} />
      <i className={styles.greyFileIcon} />
      <div className={styles.title}>Drag and drop here</div>
      <div className={styles.or}>or</div>
      <button className={styles.cta}>Browse Files</button>
    </div>
  );
};
