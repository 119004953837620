import React, { FC } from "react";
import typography from "styles/typography.module.css";
import button from "styles/button.module.css";

interface CancelModalConfirmProps {
  wallet: string;
  customerName: string;
  orderId: number;
  onConfirm: () => void;
  submitting: boolean;
  confirmed: boolean;
}
const CancelModalConfirm: FC<CancelModalConfirmProps> = ({
  wallet,
  customerName,
  orderId,
  onConfirm,
  submitting,
  confirmed,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        gap: 14,
        width: 415,
        marginBottom: 14,
      }}
    >
      {!confirmed ? (
        <>
          <h2
            className={`${typography.title} ${typography.errorText}`}
            style={{ fontWeight: 500 }}
          >
            Cancel Order
          </h2>
          <div style={{ fontWeight: 300 }}>
            You are about to cancel order number {orderId}, are you sure you
            want to cancel the order?
          </div>
          <div className={typography.regularText}>
            The customer {customerName} will be refunded for all his completed
            payments
          </div>
          <div className={typography.errorText}>
            The refund will be debited from your {wallet} wallet
          </div>
          <button
            disabled={submitting}
            onClick={onConfirm}
            style={{ marginTop: 5 }}
            className={`${button.buttonPrimary} ${
              submitting ? button.disabled : ""
            }`}
          >
            Cancel Order
          </button>
        </>
      ) : (
        <>
          <h2 className={`${typography.title} ${typography.regularText}`} style={{ fontWeight: 500 }}>
            Cancelled successfully
          </h2>
          <div style={{ fontWeight: 300 }}>
            The order has been cancelled successfully and the Customer was
            refunded for all their completed payments
          </div>
          <div className={typography.regularText}>
            Your {wallet} wallet was debited
          </div>
        </>
      )}
    </div>
  );
};

export default CancelModalConfirm;
