import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useNotAuthintecated = () => {
  const isLogedin = useSelector(({ auth }: { auth: any }) => auth.isLogedin);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogedin) {
      navigate("/", {
        replace: true,
      });
    }
  }, [isLogedin]);
};

export default useNotAuthintecated;
