import { useEffect, useState } from "react";
import styles from "./login.module.css";
import icons from "styles/icons.module.css";
import { TextField } from "@mui/material";
import button from "styles/button.module.css";
import { setNewPassword } from "datasource/Api";

import { useNavigate, useSearchParams } from "react-router-dom";

const EMAIL_REGEX = /\S+@\S+\.\S+/;

const SetPassword = () => {
  const [email, setEmail] = useState("");
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState(false);
  const [serverError, setServerError] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChanged = (event: any) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handlePasswordChanged = (event: any) => {
    setPassword(event.target.value);
  };
  const handlePasswordConfirmationChanged = (event: any) => {
    setPasswordConfirmation(event.target.value);
  };

  const loginRequest = () => {

    if (loading || !validateForm()) {
      return;
    }

    setLoading(true);
    setServerError("");
    setNewPassword({
      email,
      password,
      password_confirmation: passwordConfirmation,
      token: token ?? "",
    })
      .then((res) => {
        navigate("/login", {
          replace: true,
        });
      })
      .catch((error) => {
        const errorMessage =
          (error as any)?.response?.data?.message || "something went wrong";
        setServerError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateEmail = (email: string) => {
    const valid = EMAIL_REGEX.test(email);
    setEmailError(!valid);
    return valid;
  };

  const validatePassword = (password: string) => {
    const valid = !!password;

    setPasswordError(!valid);
    return valid;
  };
  const validateConfirmationPassword = (passwordConfirmation: string) => {
    const valid = password == passwordConfirmation;
    setPasswordConfirmationError(!valid);
    return valid;
  };

  const validateForm = () => {
    return (
      validateEmail(email) &&
      validatePassword(password) &&
      validateConfirmationPassword(passwordConfirmation)
    );
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/login", {
        replace: true,
      });
    }
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.loginBg} />
      <div className={styles.loginSection}>
        <div className={styles.loginContent}>
          <i className={`${icons.coloredLogo} ${styles.logo}`} />
          <p className={styles.loginText}>
            Welcome To Deema,
            <br />
            Please Enter your email and new password
          </p>
          {serverError && <div className={styles.error}>{serverError} </div>}
          <TextField
            fullWidth
            onChange={handleEmailChanged}
            error={emailError}
            helperText={emailError ? "Please enter a valid email" : ""}
            label="Business Email"
            size="small"
          />
          <TextField
            fullWidth
            onChange={handlePasswordChanged}
            error={passwordError}
            helperText={passwordError ? "Please your new password" : ""}
            size="small"
            label="Password"
            type="password"
          />
          <TextField
            fullWidth
            onChange={handlePasswordConfirmationChanged}
            error={passwordConfirmationError}
            helperText={passwordConfirmationError ? "not valid " : ""}
            size="small"
            label="Password Confirmation"
            type="password"
          />
          <button
            style={{ marginTop: 10 }}
            onClick={loginRequest}
            className={`${button.buttonPrimary} ${button.block} ${
              loading ? button.disabled : ""
            }`}
          >
            <span className={button.centerText}>Save Password</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
