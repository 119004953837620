import { FC } from "react";
import { WalletsModel } from "datasource/Types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, CircularProgress, TableContainer } from "@mui/material";
import shared from "styles/shared.module.css";

import { Link, useNavigate } from "react-router-dom";

type OrderesTableProps = {
  wallets: WalletsModel[];
  loading: boolean;
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 6,
  color: "var(--color-text)",
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
    paddingBottom: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "none",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  borderBottom: "1px #E3E4ED solid",
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8FA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const WalletsTable: FC<OrderesTableProps> = ({ wallets, loading }) => {
  return (
    <>
      <TableContainer>
        {!loading && (
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Merchant Id</StyledTableCell>
                <StyledTableCell align="left">
                  Merchant Business Name
                </StyledTableCell>
                <StyledTableCell align="left">Currency</StyledTableCell>
                <StyledTableCell align="left">
                  Wallet created tIME
                </StyledTableCell>
                <StyledTableCell align="left">Show Transaction</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wallets.map((row) => (
                <Row row={row} />
              ))}
            </TableBody>
          </Table>
        )}

        {loading && (
          <div className={shared.loaderWrapper}>
            <CircularProgress color="inherit" />
          </div>
        )}
      </TableContainer>
    </>
  );
};
type RowPropsType = {
  row: WalletsModel;
};
function Row({ row }: RowPropsType) {
  const navigate = useNavigate();

  return (
    <>
      <StyledTableRow
        key={row.wallet_id}
        onDoubleClick={() => navigate(`/wallets/${row.wallet_id}`)}
      >
        <StyledTableCell align="left">{row.merchant_id}</StyledTableCell>
        <StyledTableCell align="left">
          {row.merchant_business_name}
        </StyledTableCell>
        <StyledTableCell align="left">{row.currency}</StyledTableCell>
        <StyledTableCell align="left">{row.wallet_created_at}</StyledTableCell>
        <Link to={`/wallets/${row.wallet_id}`}>
          <StyledTableCell align="left">
            <Button variant="outlined" color="info" size="small">
              {" "}
              Show Transaction
            </Button>
          </StyledTableCell>
        </Link>
      </StyledTableRow>
    </>
  );
}
