export const PERMISSIONS = {
  role_management: "role_management",
  user_management: "user_management",
  branch_management: "branch_management",
  account_configuration: "account_configuration",
  view_settlement: "view_settlement",
  view_transaction: "view_transaction",
  refund_purchase: "refund_purchase",
  cancel_purchase: "cancel_purchase",
  place_in_store_purchase: "place_in_store_purchase",
  capture_purchase: "capture_purchase",
  view_purchase: "view_purchase",
  dashboard_statistics: "dashboard_statistics",
  none: "none",
};
