import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import shared from "styles/shared.module.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Loader from "components/loaders/Loader";
import { CreateBranchType } from "datasource/Types";
function BranchForm({
  initialValues,
  handleSubmit,
  isLoding,
  title,
}: {
  initialValues: CreateBranchType;
  title: string;
  isLoding: boolean;
  handleSubmit: (values: CreateBranchType) => void;
}) {
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("required"),
  });

  return (
    <div className={shared.page}>
      {isLoding && <Loader />}
      <Box>
        <Typography variant="h5" mb={5} fontWeight="700">
          {title}
        </Typography>
        <Formik
          validateOnChange={false}
          validateOnBlur
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <Grid spacing={3} container>
                <Grid item xs={12} lg={5}>
                  {" "}
                  <Grid spacing={3} container mb={4}>
                    <Grid item xs={12}>
                      <TextField
                        {...props.getFieldProps({ name: "name" })}
                        variant="outlined"
                        error={!!props.errors.name}
                        fullWidth
                        label="branch name "
                        name="name"
                      />
                      <FormHelperText
                        sx={(theme) => ({
                          color: theme.palette.error.main,
                        })}
                      >
                        {props.errors.name}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        sx={{ boxShadow: "none" }}
                        size="large"
                        variant="contained"
                      >
                        save branch
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default BranchForm;
