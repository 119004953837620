import { Button, Grid, Stack } from "@mui/material";

import shared from "styles/shared.module.css";
import { getRoles } from "datasource/Api";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import RoleCard from "./RoleCard";
import Loader from "components/loaders/Loader";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
const Roles = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: () => getRoles(),
  });


  return (
    <div className={shared.page}>
      {isLoading && <Loader />}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {" "}
        <div className={shared.pageTitle}> Roles</div>{" "}
        <Link to={"/roles/new"}>
          <Button variant="outlined">Create new role</Button>
        </Link>
      </Stack>
      <Grid container spacing={2}>
        {data?.data.map((role) => (
          <Grid xs={12} md={6} lg={4}  item key={role.id}>
            <RoleCard role={role} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default WithCheckPermission(Roles, PERMISSIONS.role_management);
