import styles from "./braches.module.css";
import { Button, Paper, Stack, TableContainer } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import shared from "styles/shared.module.css";
import { getMerchentBranches, toggleBranchHidden } from "datasource/Api";
import { useMutation, useQuery } from "@tanstack/react-query";
import PageLoader from "components/loaders/Loader";
import Loader from "core/Loader";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Edit } from "@mui/icons-material";
import WithCheckPermission from "components/WithCheckPermission";
import { PERMISSIONS } from "config/permissions";
const Branches = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["branches"],
    queryFn: () => getMerchentBranches(),
  });
  const { isPending, mutate: mutateToggleBranchHidden } = useMutation({
    mutationFn: toggleBranchHidden,

    onSuccess(data) {
      refetch();
      toast.success(
        `${data.data.name} was ${
          data.data.is_hidden ? "hidden" : "showed"
        } successfully`,
        {
          autoClose: 2000,
          position: "bottom-right",
        }
      );
    },
  });
  const navigate = useNavigate();

  return (
    <div className={shared.page}>
      {isPending && <PageLoader />}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {" "}
        <div className={shared.pageTitle}> Branches</div>{" "}
        <Link to={"/branches/new"}>
          <Button variant="outlined">Create new brach</Button>
        </Link>
      </Stack>
      <Paper sx={{ padding: "20px" }}>
        <>
          <TableContainer>
            {!isLoading && (
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">branch id </TableCell>
                    <TableCell align="left">name </TableCell>

                    <TableCell align="center">status</TableCell>
                    <TableCell align="center">acrions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>

                      <TableCell align="center">
                        <div
                          className={
                            row.is_hidden ? styles.desActive : styles.active
                          }
                        >
                          {row.is_hidden ? "Hidden" : "Active"}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Stack
                          direction="row"
                          justifyContent="center"
                          gap={1}
                          alignItems="center"
                        >
                          <Button
                            onClick={() => {
                              mutateToggleBranchHidden(row.id);
                            }}
                            size="small"
                            variant="outlined"
                          >
                            {!row.is_hidden ? "Hidden" : "Show"}
                          </Button>
                          <Button
                            endIcon={<Edit fontSize="small" />}
                            onClick={() => {
                              navigate(`/branches/${row.id}`);
                            }}
                            size="small"
                            variant="outlined"
                          >
                            Edit
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {isLoading && <Loader />}
          </TableContainer>
        </>
      </Paper>
    </div>
  );
};

export default WithCheckPermission(Branches, PERMISSIONS.branch_management);
