import axios from "axios";
import { API_BASE_URL, PER_PAGE_DEFAULT } from "config/base";

import {
  LoginRequestModel,
  AuthModel,
  ResponseModel,
  OrderPaging,
  DashboardModel,
  WebhookInfo,
  UpdateWebhookRequestModel,
  GetOrderesRequestModel,
  GetSattlmentsRequestFilter,
  GetSattlmentDetailsRequestModel,
  PlaceOrderRes,
  BranchModel,
  CreateBranchType,
  BranchUserModel,
  CreateUserType,
  CreateRoleType,
  RoleModel,
  PermissionType,
  UserDetailsModel,
  OrderPlace,
} from "./Types";
import { store } from "store";
import { logout } from "store/authReducer";

const http = axios.create({
  baseURL: API_BASE_URL,
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(logout());
    }
    throw error;
  }
);

http.interceptors.request.use((config) => {
  let accessToken = null;
  accessToken = `Bearer ${store.getState().auth.accessToken}`;

  if (accessToken) {
    config.headers.Authorization = accessToken;
  }

  return config;
});

export const login = ({
  email,
  password,
}: LoginRequestModel): Promise<ResponseModel<AuthModel>> => {
  return http
    .post("merchant/login", { email, password })
    .then((res) => res.data)
    .then((res) => ({
      ...res,
      data: {
        ...res.data,
        accessToken: res.data.access_token,
      },
    }));
};

export const getOrders = ({
  pagingData,
  filters = {},
}: GetOrderesRequestModel): Promise<ResponseModel<OrderPaging>> => {
  let searchParams = {};
  if (filters.from_date) {
    searchParams = { ...searchParams, from_date: filters.from_date };
  }
  if (filters.to_date) {
    searchParams = { ...searchParams, to_date: filters.to_date };
  }
  if (filters.order_status && filters?.order_status !== "ALL") {
    searchParams = { ...searchParams, order_status: filters.order_status };
  }
  if (filters.search) {
    searchParams = { ...searchParams, search: filters.search };
  }
  if (filters.type && filters?.type !== "ALL") {
    searchParams = { ...searchParams, type: filters.type };
  }
  if (
    filters.branch_id &&
    filters.type != OrderPlace.ONLINE &&
    filters?.branch_id !== "ALL"
  ) {
    searchParams = {
      ...searchParams,
      type: filters.type,
      branch_id: filters.branch_id,
    };
  }
  return http
    .get("merchant/orders_list", {
      params: {
        page: pagingData.page || 1,
        per_page: pagingData.perPage || PER_PAGE_DEFAULT,
        merchant_id: store.getState().auth.user?.id || null,
        ...searchParams,
      },
    })
    .then((res) => res.data);
};
export const getPendingOrders = ({
  pagingData,
  filters = {},
}: GetOrderesRequestModel): Promise<ResponseModel<OrderPaging>> => {
  let searchParams = {};
  if (filters.from_date) {
    searchParams = { ...searchParams, from_date: filters.from_date };
  }
  if (filters.to_date) {
    searchParams = { ...searchParams, to_date: filters.to_date };
  }

  if (filters.search) {
    searchParams = { ...searchParams, search: filters.search };
  }
  if (filters.type && filters?.type !== "ALL") {
    searchParams = { ...searchParams, type: filters.type };
  }
  if (
    filters.branch_id &&
    filters.type != OrderPlace.ONLINE &&
    filters?.type !== "ALL"
  ) {
    searchParams = {
      ...searchParams,
      type: filters.type,
      branch_id: filters.branch_id,
    };
  }
  return http
    .get("/merchant/pending_capture", {
      params: {
        page: pagingData.page || 1,
        per_page: pagingData.perPage || PER_PAGE_DEFAULT,
        merchant_id: store.getState().auth.user?.id || null,
        ...searchParams,
      },
    })
    .then((res) => res.data);
};
export const captureOrder = (
  purchasesIds: number[]
): Promise<ResponseModel<any>> => {
  return http
    .post(`/merchant/purchases/capture`, { purchases: purchasesIds })
    .then((res) => res.data);
};
export const captureAllOrder = (
 
): Promise<ResponseModel<any>> => {
  return http
    .post(`/merchant/purchases/capture-all`)
    .then((res) => res.data);
};
export const logoutApi = (): Promise<ResponseModel<any>> => {
  return http.post(`/merchant/logout`).then((res) => res.data);
};

export const getWebhookInfo = (): Promise<ResponseModel<WebhookInfo>> => {
  return http.get("merchant/webhook").then((res) => res.data);
};

export const updateWebhookInfo = ({
  webhookUrl,
  headerKey,
  headerValue,
}: UpdateWebhookRequestModel): Promise<ResponseModel<any>> => {
  return http
    .patch("merchant/webhook", {
      webhook_url: webhookUrl,
      header_key: headerKey,
      header_value: headerValue,
    })
    .then((res) => res.data);
};

export const cancelOrderRequest = (id: number): Promise<ResponseModel<any>> => {
  return http
    .post("merchant/purchases/cancel", {
      purchase_id: id,
    })
    .then((res) => res.data);
};

export const refundOrderRequest = (
  id: number,
  amount: number
): Promise<ResponseModel<any>> => {
  return http
    .post("merchant/purchases/refund", {
      purchase_id: id,
      amount: amount,
    })
    .then((res) => res.data);
};

export const getSattlments = ({
  pagingData,
  filters = {},
}: GetSattlmentsRequestFilter): Promise<ResponseModel<any>> => {
  let searchParams = {};
  if (filters.from_date) {
    searchParams = { ...searchParams, from_date: filters.from_date };
  }
  if (filters.to_date) {
    searchParams = { ...searchParams, to_date: filters.to_date };
  }
  if (filters.sattlment_type) {
    searchParams = { ...searchParams, status: filters.sattlment_type };
  }
  if (filters.search) {
    searchParams = { ...searchParams, search: filters.search };
  }
  return http
    .get("settlements", {
      params: {
        page: pagingData.page || 1,
        per_page: pagingData.perPage || PER_PAGE_DEFAULT,
        merchant_id: store.getState().auth.user?.id || null,
        descending: 1,
        ...searchParams,
      },
    })
    .then((res) => res.data);
};
export const getWallets = ({
  pagingData,
  filters = {},
}: any): Promise<ResponseModel<any>> => {
  let searchParams = {};

  if (filters.search) {
    searchParams = { ...searchParams, search: filters.search };
  }

  return http
    .get(`/merchant/wallets`, {
      params: {
        page: pagingData.page || 1,
        per_page: pagingData.perPage || PER_PAGE_DEFAULT,
        ...searchParams,
      },
    })
    .then((res) => res.data);
};
export const getWalletsTransactions = ({
  pagingData,
  filters = {},
  walleId,
}: any): Promise<ResponseModel<any>> => {
  let searchParams = {};

  if (filters.search) {
    searchParams = { ...searchParams, search: filters.search };
  }

  return http
    .get(`/merchant/wallets/${walleId}/transactions`, {
      params: {
        page: pagingData.page || 1,
        per_page: pagingData.perPage || PER_PAGE_DEFAULT,
        ...searchParams,
      },
    })
    .then((res) => res.data);
};
export const getDocuments = () => {
  return http.get(`documents`).then((res) => res.data);
};

export const submitDocumentsRequest = (formData: FormData) => {
  return http.post(`documents`, formData).then((res) => res.data);
};

export const getDashboard = (): Promise<ResponseModel<DashboardModel>> => {
  return http.get("merchant/dashboard").then((res) => res.data);
};
export const setNewPassword = (
  data: LoginRequestModel & {
    token: string;
    password_confirmation: string;
  }
): Promise<ResponseModel<AuthModel>> => {
  return http.post("/business/set_password", data).then((res) => res.data);
};
export const fetcUserData = () => {
  return http.get("check_authorization").then((res) => res.data);
};
export const getUserDetails = (): Promise<ResponseModel<UserDetailsModel>> => {
  return http.get(`/merchant/users/details`).then((res) => res.data);
};
export const responseErrorMessage = (err: any) => {
  return err.message;
};
export const getOrderDetails = (orderId: number) => {
  return http.get(`merchant/purchases/${orderId}`).then((res) => res.data);
};
export const getSettlementsDetails = ({
  settlementId,
  filters,
}: GetSattlmentDetailsRequestModel): Promise<ResponseModel<any>> => {
  let searchParams = {};
  if (filters?.transaction_action && filters?.transaction_action != "All") {
    searchParams = {
      ...searchParams,
      transaction_action: filters.transaction_action,
    };
  }
  if (filters?.transaction_type && filters?.transaction_type != "All") {
    searchParams = {
      ...searchParams,
      transaction_type: filters.transaction_type,
    };
  }
  return http
    .get(`/settlements/${settlementId}/details`, {
      params: searchParams,
    })
    .then((res) => res.data);
};
export const transfareRequest = (sattlmentId: number) => {
  return http
    .put(`settlements/${sattlmentId}/transfer`, {})
    .then((res) => res.data);
};
///////with react query////////////
export const getMerchentDetails = (): Promise<ResponseModel<any>> => {
  return http.get("merchant/merchant_details");
};
export const placeOrderInStore = (
  values: any
): Promise<ResponseModel<PlaceOrderRes>> => {
  return http.post("merchant/purchases/place_in_store_purchase", values);
};

export const getMerchentBranches = (): Promise<
  ResponseModel<BranchModel[]>
> => {
  return http.get(`/merchant/branches`).then((res) => res.data);
};
export const getAvailableBranches = (
  online: boolean = true
): Promise<ResponseModel<BranchModel[]>> => {
  return http
    .get(`/merchant/get_available_branches`, { params: { online } })
    .then((res) => res.data);
};
export const createBranch = (
  values: CreateBranchType
): Promise<ResponseModel<BranchModel>> => {
  return http.post(`/merchant/branches`, values).then((res) => res.data);
};
export const updateBranch = (varibles: {
  values: CreateBranchType;
  id: string;
}): Promise<ResponseModel<BranchModel>> => {
  return http
    .put(`/merchant/branches/${varibles.id}`, varibles.values)
    .then((res) => res.data);
};
export const toggleBranchHidden = (
  id: number
): Promise<ResponseModel<BranchModel>> => {
  return http.patch(`/merchant/branches/${id}`).then((res) => res.data);
};
export const setFcmToken = (fcm_token: string): Promise<any> => {
  return http
    .post(`/merchant/notification/set_fcm_token`, { fcm_token })
    .then((res) => res.data);
};

export const getMerchentUsers = (): Promise<
  ResponseModel<BranchUserModel[]>
> => {
  return http.get(`/merchant/users`).then((res) => res.data);
};
export const getMerchentUserById = (
  id: string | number
): Promise<ResponseModel<BranchUserModel>> => {
  return http.get(`/merchant/users/${id}`).then((res) => res.data);
};
export const createUser = (
  values: CreateUserType
): Promise<ResponseModel<BranchUserModel>> => {
  return http.post(`/merchant/users`, values).then((res) => res.data);
};
export const updateUser = (varibles: {
  values: CreateUserType;
  id: string;
}): Promise<ResponseModel<BranchUserModel>> => {
  return http
    .put(`/merchant/users/${varibles.id}`, varibles.values)
    .then((res) => res.data);
};
export const toggleUserHidden = (
  id: number
): Promise<ResponseModel<BranchUserModel>> => {
  return http.patch(`/merchant/users/${id}`).then((res) => res.data);
};

export const getPermissions = (): Promise<ResponseModel<PermissionType[]>> => {
  return http.get(`/merchant/rbac/permission`).then((res) => res.data);
};
export const getRoles = (): Promise<ResponseModel<RoleModel[]>> => {
  return http.get(`/merchant/rbac/role`).then((res) => res.data);
};
export const getRoleById = (
  id: string | number
): Promise<ResponseModel<RoleModel>> => {
  return http.get(`/merchant/rbac/role/${id}`).then((res) => res.data);
};
export const createRole = (
  values: CreateRoleType
): Promise<ResponseModel<RoleModel>> => {
  return http.post(`/merchant/rbac/role`, values).then((res) => res.data);
};
export const updateRole = (varibles: {
  values: CreateRoleType;
  id: string;
}): Promise<ResponseModel<RoleModel>> => {
  return http
    .put(`/merchant/rbac/role/${varibles.id}`, varibles.values)
    .then((res) => res.data);
};
