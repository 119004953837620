import { createSlice } from "@reduxjs/toolkit";
import { TOKEN_STORAGE_KEY, USER_STORAGE_KEY } from "config/base";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    permissinos: [],
    user: cookies.get(TOKEN_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || "{}")
      : null,
    accessToken: cookies.get(TOKEN_STORAGE_KEY),
    isLogedin: cookies.get(TOKEN_STORAGE_KEY) ? true : false,
  },
  reducers: {
    persistAuthData: (state, { payload }) => {
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(payload.user));
      cookies.set(TOKEN_STORAGE_KEY, payload?.accessToken || "", {
        secure: true,
        sameSite: "strict",
      });
      state.accessToken = payload.accessToken;
      state.user = payload.user;
      state.isLogedin = !!payload.accessToken ? true : false;
    },
    persistUser: (state, { payload }) => {
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(payload));
      state.user = payload;
    },
    savePermissinos: (state, { payload }) => {
      state.permissinos = payload;
    },
    logout: (state) => {

      cookies.remove(TOKEN_STORAGE_KEY);
      localStorage.removeItem(USER_STORAGE_KEY);
      state.accessToken = "";
      state.user = null;
      state.isLogedin = false;
      state.permissinos = [];
    },
  },
});

export const { persistAuthData, logout, persistUser ,savePermissinos} = authSlice.actions;

export default authSlice.reducer;
